<template>
  <base-section
    id="team"
    class="accent"
  >
    <div class="bg-pixel" />
    <base-section-heading
      class="primary--text"
      title="Team"
    />
    <v-container class="pr-16 pl-16">
      <h3

        class="text-h5  font-weight-bold mr-6 ml-6 mb-4 text-center"
      >
        Wir sind Profis auf unserem Gebiet und bringen nebst Jahrzehnte-langer Erfahrung,
        Herzblut, Engagement und pädagogischse Unterrichtsknow mit.
      </h3>
    </v-container>
    <div
      class="py-14"
    />
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-avatar
            size="190"
            elevation="4"
            class="elevation-10"
          >
            <img
              :src="require('@/assets/BUZ.jpg')"
              alt="Buz"
            >
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div class="ml-6 mr-8">
            <h3 class="text-uppercase text-h5 primary--text">
              Björn Buz Meier
            </h3>
            <div
              class="py-2"
            />
            <h3
              class="text-h6 mr-12 secondary--text text-none text-left"
            >
              Tänzer, Choreograf, Bewegungspädagoge, Kulturvermittler und Szene-Profi.
            </h3>
          </div>
          <div
            class="py-3"
          />
          <v-expansion-panels
            flat
            class="hello"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="list-header">
                + CV
              </v-expansion-panel-header>
              <v-expansion-panel-content class="list-content text-none mr-10">
                Björn „BUZ“ Meier, geboren 1973, lebt Tanz & Kultur seit nun über 30 Jahren.
                <br><br>
                Als Tänzer ist Buz mit diversen Ensembles auf der Bühne zu sehen. Seit 2013 choreografiert er ausserdem
                erfolgreich etliche Dance-Companies und Shows.
                <br><br>
                Seit über 15 Jahren unterrichtet und choacht Buz Kinder und junge Erwachsene. Mit viel Engagement und
                Herzblut. Nebst regulären Unterrichtstunden und Workshops unterrichtet er u.a. auch Kinder und
                Jugendliche mit geistigen und körperlichen Beeinträchtigungen. So zum Beispiel in Jugendgefängnissen,
                sozialen Institutionen oder auch in Einrichtungen für psychisch beeinträchtigte Menschen.
                <br><br>
                Buz ist einer der ältesten „Breaker“ der Schweiz. Mit seinen 48 Jahren nimmt er noch immer aktiv an
                Wettbewerben (Battles) teil und hält dabei noch immer ganz vorne mit.
                <br><br>
                Er setzt sich ausserdem unentwegt für den Nachwuchs ein. 1996 initiierte Buz die Plattform „Freies
                Training” - ein offenes und kostenloses autonomes Training im Jugendkulturhaus Dynamo (täglich 10.00
                Uhr bis 22.00 Uhr).<br><br>
                Kurz: Buz bewegt. Als Tänzer, Choreograph und als Mensch.
                <div
                  class="py-6"
                />
                <div class="text-left">
                  <v-dialog
                    v-model="dialog"
                    width="1000"
                    height="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <base-btn
                        :tile="false"
                        color="primary"
                        rounded
                        outlined
                        dense
                        class="secondary--text font-weight-bold"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Portfolio
                      </base-btn>
                    </template>

                    <v-card>
                      <v-img
                        :src="require('@/assets/Portfolio_BUZ.jpg')"
                        class="flex-fill"
                      />
                    </v-card>
                  </v-dialog>
                </div>
              </v-expansion-panel-content>
              <v-divider
                horizontal
                width="590"
                class="divider mx-6"
              />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-center"
        />
      </v-row>
      <div
        class="py-14"
      />
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-avatar
            size="190"
            class="elevation-10"
          >
            <img
              :src="require('@/assets/Mercy.jpg')"
              alt="Buz"
            >
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div class="pl-6 mr-8">
            <h3 class="text-uppercase text-h5 primary--text">
              Mercy
            </h3>
            <div
              class="py-2"
            />
            <h3
              class="text-h6 mr-10 secondary--text text-none text-left"
            >
              Tänzer, Choreograf, Kulturvermittler und Szene-Profi.
            </h3>
          </div>
          <div
            class="py-3"
          />
          <v-expansion-panels
            flat
            class="hello"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="list-header">
                + CV
              </v-expansion-panel-header>
              <v-expansion-panel-content class="list-content text-none mr-10">
                Walat Bilal, aka BBoy No-Mercy, geboren 1988, fand sehr früh die Begeisterung für die Breakingkultur.
                Als Kind war es für ihn "nur" Spass und ein wenig Turnen. Jetzt kann er sich das Lebens ohne Breaking
                gar nicht vorstellen. BBoy No-Mercy hat seinen Platz in der BBoy/Girl-Szene in der Schweiz gesichert und
                nimmt weiterhin an Wettkämpfe (Battles) teil.<br>
                In seinen 20 Jahren als BBoy, durfte er auf verschiedenen
                Bühne tanzen und Erfahrung sammeln. Mit der Gruppe "Ghost Rockz" wurde er zwei Mal Schweizermeister und
                war acuh einige Mal am Red Bull BC Schweizerqualifikation dabei. Sei es in Musicals, Theater oder
                Battles, BBoy No-Mercy konnte sein Können immer unterbeweisstellen. Er weiss auch wie wichtig es ist,
                dass die Jugend sich bewegt und eine Leidenschaft findet. Deshalb unterrichtet er regelmässig und gib
                sein Wissen sehr gerne weiter. "Solange das Feuer in einem selbst noch brennt, kann man das bei seinen
                Schülern einfacher entfachten".
                <!--div
                  class="py-6"
                />
                <div class="text-left">
                  <v-dialog
                    v-model="dialog"
                    width="500"
                  >
                    <template #activator="{ on, attrs }">
                      <base-btn
                        :tile="false"
                        color="primary"
                        rounded
                        outlined
                        dense
                        class="secondary--text font-weight-bold"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Portfolio
                      </base-btn>
                    </template>

                    <v-card>
                      <v-img
                        :src="require('@/assets/Portfolio_BUZ.jpg')"
                        class="flex-fill"
                      />
                    </v-card>
                  </v-dialog>
                </div-->
              </v-expansion-panel-content>
              <v-divider
                horizontal
                width="590"
                class="divider mx-6"
              />
            </v-expansion-panel>
            <v-expansion-panel />
          </v-expansion-panels>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-center"
        />
      </v-row>
      <div
        class="py-16"
      />
    </v-container>
    <div class="bg-pixel-down" />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
    }),
  }
</script>

<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}

.v-application .accent {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.list-header {
  font-size: 1.3rem !important;
  font-weight: 700;
}

.list-content {
  font-size: 1rem !important;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: none !important;
}

.divider {
  border-color: rgba(12,12,12, 0.59) !important;
  border-width: 2px 0 0 0;
  margin-botttom: 4px;
}

.bg-pixel {
  background-image: url('~@/assets/gradient-black.png');
  background-repeat: repeat-x;
  height: 80px;
  background-size: contain;
  width: 100%;
  position: relative;
  z-index: 3;
}

.bg-pixel-down {
  background-image: url('~@/assets/gradient-black.png');
  background-repeat: repeat-x;
  transform: scaleY(-1);
  height: 80px;
  background-size: contain;
  margin-top: -80px;
  width: 100%;
  position: absolute;
  z-index: 3;
}

.v-application .text-h5 {
  font-weight: 900;
  font-size: 1.5rem;
}
</style>
